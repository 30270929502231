import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  reportDetail: null,
  reportGeneral: null,
  reportSupplier: null,
  reportRevenue: null,
  reportControl: null,
  reportStatistic: null,
  reportQuantity: null,
  totalMessageReport: null,
  totalPriceReport: null,
  totalMessageSuccess: null,
  totalMessageError: null,
  callBackParams: null,
  suppliers: null,
  pageableDetail: {},
  pageableGeneral: {},
  pageableOperator: {},
  pageableRevenue: {},
  pageableStatistic: {},
  pageableControl: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  suppliers: (state) => state.suppliers,
  reportDetail: (state) => state.reportDetail,
  reportGeneral: (state) => state.reportGeneral,
  reportSupplier: (state) => state.reportSupplier,
  reportRevenue: (state) => state.reportRevenue,
  reportStatistic: (state) => state.reportStatistic,
  reportControl: (state) => state.reportControl,
  reportQuantity: (state) => state.reportQuantity,
  totalMessageReport: (state) => state.totalMessageReport,
  totalPriceReport: (state) => state.totalPriceReport,
  totalMessageError: (state) => state.totalMessageError,
  totalMessageSuccess: (state) => state.totalMessageSuccess,
  callBackParams: (state) => state.callBackParams,
  pageableDetail: (state) => state.pageableDetail,
  pageableGeneral: (state) => state.pageableGeneral,
  pageableOperator: (state) => state.pageableOperator,
  pageableRevenue: (state) => state.pageableRevenue,
  pageableStatistic: (state) => state.pageableStatistic,
  pageableControl: (state) => state.pageableControl,
  searchParams: (state) => state.search,
};

const actions = {
  async getSuppliers({ commit, getters }, params) {
    try {
      const response = await axios.get(`/suppliers`, { params: params });

      if (response.status == 200) {
        commit("setSuppliers", response.data);
        return response;
      }
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getReport({ commit, getters }, params) {
    console.log("paramsss", params);
    try {
      const dataParams = {};
      if (params.typePage && params.typePage == "PAGE") {
        if (params.dataPage.phoneNumber) {
          dataParams.phoneNumber = params.dataPage.phoneNumber;
        }
        if (params.dataPage.companyIds) {
          dataParams.companyIds = params.dataPage.companyIds;
        }
        if (params.dataPage.companyId) {
          dataParams.companyIds = [params.dataPage.companyId.id];
        }
        if (params.dataPage.brandNameIds) {
          dataParams.brandNameIds = params.dataPage.brandNameIds;
        }
        if (params.dataPage.oaIds) {
          dataParams.oaIds = params.oaIds;
        }
        if (params.dataPage.supplierId) {
          dataParams.supplierId = params.dataPage.supplierId;
        }
        if (params.dataPage.homeNetworkIds) {
          dataParams.homeNetworkIds = params.dataPage.homeNetworkIds;
        }

        if (params.dataPage.sectorIds) {
          dataParams.sectorIds = params.dataPage.sectorIds;
        }

        if (params.dataPage.messageTypeIds) {
          dataParams.messageTypeIds = params.dataPage.messageTypeIds;
        }

        if (params.dataPage.serviceType) {
          dataParams.serviceType = params.dataPage.serviceType;
        }

        if (params.dataPage.productIds) {
          dataParams.productIds = params.dataPage.productIds;
        }

        if (params.dataPage.templateIds) {
          dataParams.templateIds = params.dataPage.templateIds;
        }
        if (typeof params.dataPage.isPrepaid === "boolean") {
          dataParams.isPrepaid = params.dataPage.isPrepaid;
        }
        if (params.dataPage.startDate) {
          dataParams.startDate = params.dataPage.startDate;
        }
        if (params.dataPage.endDate) {
          dataParams.endDate = params.dataPage.endDate;
        }
        if (params.dataPage.type) {
          dataParams.type = params.dataPage.type;
        }
        if (params.dataPage.status) {
          dataParams.status = params.dataPage.status;
        }
      } else {
        if (params.phoneNumber) {
          dataParams.phoneNumber = params.phoneNumber;
        }
        if (params?.companyIds?.length) {
          dataParams.companyIds = params.companyIds.map((item) => item.id);
        }
        if (params.companyId) {
          dataParams.companyIds = [params.companyId];
        }
        if (params?.brandNameIds?.length) {
          dataParams.brandNameIds = params.brandNameIds.map((item) => item.id);
        }
        if (params?.oaIds?.length) {
          dataParams.oaIds = params.oaIds.map((item) => item.id);
        }
        if (params.supplierId) {
          dataParams.supplierId = params.supplierId;
        }
        if (params?.homeNetworkIds?.length) {
          dataParams.homeNetworkIds = params.homeNetworkIds.map(
            (item) => item.id
          );
        }

        if (params?.sectorIds?.length) {
          dataParams.sectorIds = params.sectorIds.map((item) => item.id);
        }

        if (params?.productIds?.length) {
          dataParams.productIds = params.productIds.map((item) => item.id);
        }

        if (params?.messageTypeIds?.length) {
          dataParams.messageTypeIds = params.messageTypeIds.map(
            (item) => item.id
          );
        }

        if (params?.templateIds?.length) {
          dataParams.templateIds = params.templateIds.map((item) => item.id);
        }

        if (typeof params.isPrepaid === "boolean") {
          dataParams.isPrepaid = params.isPrepaid;
        }

        if (params.status) {
          dataParams.status = params.status.map((item) => item);
        }
        if (params.startDate) {
          dataParams.startDate = params.startDate;
        }
        if (params.endDate) {
          dataParams.endDate = params.endDate;
        }
        if (params.serviceType) {
          dataParams.serviceType = params.serviceType;
        }
      }
      dataParams.pageNumber = params.pageNumber;
      dataParams.pageSize = params.pageSize ? Number(params.pageSize) : 20;
      commit("setPaging", {
        pageSize: dataParams.pageSize,
        page: dataParams.pageNumber,
      });
      commit("setCallback", {
        dataParams,
      });
      let response;
      switch (params.typeReport) {
        case "DETAIL":
          response = await axios.post(`/report/detail`, dataParams);
          dataParams.typeReport = "DETAIL";
          commit("setReportDetail", response.data);
          break;
        case "GENERAL":
          response = await axios.post(`/report/general`, dataParams);
          dataParams.typeReport = "GENERAL";
          commit("setReportGeneral", response.data.page);
          commit("setReportBottom", response.data);
          break;
        case "STATISTIC":
          response = await axios.post(`/report/statistical`, dataParams);
          dataParams.typeReport = "STATISTIC";
          commit("setReportStatistic", response.data.page);
          commit("setReportBottom", response.data);
          break;
        case "REVENUE":
          response = await axios.post(`/report/internal_revenue`, dataParams);
          dataParams.typeReport = "REVENUE";
          commit("setReportRevenue", response.data.page);
          commit("setReportBottom", response.data);
          break;
        case "SUPPLIER":
          response = await axios.post(`/report/control/supplier`, dataParams);
          dataParams.typeReport = "SUPPLIER";
          commit("setReportSupplier", response.data.page);
          commit("setReportBottom", response.data);
          break;
        case "CONTROL":
          response = await axios.post(`/report/control`, dataParams);
          dataParams.typeReport = "CONTROL";
          commit("setReportControl", response.data.page);
          commit("setReportBottom", response.data);
          break;
        case "QUANTITY":
          response = await axios.post(`/report/total`, dataParams);
          dataParams.typeReport = "QUANTITY";
          commit("setReportQuantity", response.data);
          break;
        default:
          break;
      }
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async exportFile({ commit }, params) {
    console.log("paramsssssssss", params);
    try {
      const dataParams = {};
      if (params.phoneNumber) {
        dataParams.phoneNumber = params.phoneNumber;
      }
      if (params.companyIds) {
        dataParams.companyIds = params.companyIds;
      }
      if (params.companyId) {
        dataParams.companyIds = [params.companyId.id];
      }
      if (params.brandNameIds) {
        dataParams.brandNameIds = params.brandNameIds;
      }
      if (params.supplierId) {
        dataParams.supplierId = params.supplierId;
      }
      if (params.oaIds) {
        dataParams.oaIds = params.oaIds;
      }
      if (params.homeNetworkIds) {
        dataParams.homeNetworkIds = params.homeNetworkIds;
      }

      if (params.sectorIds) {
        dataParams.sectorIds = params.sectorIds;
      }

      if (params.messageTypeIds) {
        dataParams.messageTypeIds = params.messageTypeIds;
      }

      if (params.serviceType) {
        dataParams.serviceType = params.serviceType;
      }

      if (typeof params.isPrepaid === "boolean") {
        dataParams.isPrepaid = params.isPrepaid;
      }
      if (params.templateIds) {
        dataParams.templateIds = params.templateIds;
      }
      if (params.startDate) {
        dataParams.startDate = params.startDate;
      }
      if (params.endDate) {
        dataParams.endDate = params.endDate;
      }
      if (params.type) {
        dataParams.type = params.type;
      }
      if (params.status) {
        dataParams.status = params.status;
      }
      dataParams.pageNumber = params.pageNumber;
      dataParams.pageSize = Number(params.pageSize);
      const response = await axios.post(`/report/export`, dataParams);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getStatusFile({ commit }, params) {
    try {
      const response = await axios.get(`/report/export/status`, {
        params: params,
      });
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setReportDetail(state, data) {
    state.reportDetail = data.data;
    state.pageableDetail = data.pageable;
  },
  setReportGeneral(state, data) {
    state.reportGeneral = data.data;
    state.pageableGeneral = data.pageable;
  },
  setReportSupplier(state, data) {
    state.reportSupplier = data.data;
    state.pageableOperator = data.pageable;
  },
  setReportControl(state, data) {
    state.reportControl = data.data;
    state.pageableControl = data.pageable;
  },
  setReportQuantity(state, data) {
    state.reportQuantity = data.data;
  },
  setReportRevenue(state, data) {
    state.reportRevenue = data.data;
    state.pageableRevenue = data.pageable;
  },
  setReportBottom(state, data) {
    state.totalMessageReport = data.totalMessage;
    state.totalPriceReport = data.totalPrice;
    state.totalMessageError = data.totalMessageError;
    state.totalMessageSuccess = data.totalMessageSuccess;
  },
  setReportStatistic(state, data) {
    state.reportStatistic = data.data;
    state.pageableStatistic = data.pageable;
  },
  setCallback(state, data) {
    state.callBackParams = data.dataParams;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
  },
  setSuppliers(state, data) {
    state.suppliers = data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
