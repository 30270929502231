import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  orders: null,
  orderHis: null,
  pageable: {},
  pageableTransactions: null,
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  orders: (state) => state.orders,
  orderHis: (state) => state.orderHis,
  pageable: (state) => state.pageable,
  pageableTransactions: (state) => state.pageableTransactions,
  searchParams: (state) => state.search,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getOrders({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    console.log("brandname serch", params, searchParam);

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params.companyIds = params.companyIds.map((item) => item.id).join(",");
    }
    if (params?.serviceIds?.length) {
      params.serviceIds = params.serviceIds.map((item) => item.id).join(",");
    }
    if (params.status && typeof params.status == "object") {
      params["status"] = params.status.map((item) => item).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/orders?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setOrders", response.data);
      return response;
    }
    return [];
  },

  async getOrderTransactions({ commit }, params) {
    console.log("orderSms/getTransactions", params);
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    const response = await axios.get(`/orders/${params.id}/history`, {
      params: params,
    });

    if (response.status == 200) {
      commit("setOrderTransactions", response.data);
      return response;
    }
    return [];
  },

  async createOrder({ commit }, params) {
    try {
      console.log("create Order bending");
      let formData = new FormData();
      if (params.company) {
        formData.append("companyId", params.company.id);
      }
      if (params.wallet) {
        formData.append("walletId", params.wallet.id);
      }
      if (params.totalAmount.toString().includes(".")) {
        formData.append(
          "totalAmount",
          params.totalAmount.toString().replaceAll(".", "")
        );
      } else {
        formData.append("totalAmount", params.totalAmount);
      }
      let response = await axios.post(`/orders`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
  async updateOrder({ commit }, params) {
    try {
      console.log("create Order bending");
      let formData = new FormData();
      // formData.append('userId', params.user.id)
      if (params.company) {
        formData.append("companyId", params.company.id);
      }
      if (params.wallet) {
        formData.append("walletId", params.wallet.id);
      }
      if (params.totalAmount.toString().includes(".")) {
        formData.append(
          "totalAmount",
          params.totalAmount.toString().replaceAll(".", "")
        );
      } else {
        formData.append("totalAmount", params.totalAmount);
      }
      let response = await axios.put(`/orders/${params.id}`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async deleteOrder({ commit }, params) {
    // const response = await axios.delete(`/orders/${params.id}`)
    // return response
    try {
      const response = await axios.delete(`/orders/${params.id}`);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setOrderTransactions(state, data) {
    state.orderHis = data.data
    state.pageableTransactions = data.pageable;
  },
  setOrders(state, data) {
    state.orders = data.data;
    state.pageable = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
