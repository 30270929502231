// Vuetify
import "vuetify/styles";
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "./custom.css"; // Ensure you are using css-loader

import '@/styles/main.scss'

// using Labs' components
import { VDataTable } from "vuetify/labs/components";
import { VBtn, VCardText, VCol } from "vuetify/components";

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#CCCCCC",
    surface: "#FFFFFF",
    primary: "#2f2ff3",
    "primary-lighten-1": "#2196F3",
    "primary-darken-1": "#17263a",
    secondary: "#FF0000",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable,
  },
  directives,
  icons: {
    // This is already the default value - only for display purposes
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
  aliases: {
    VBtnSmall: VBtn,
    VCardTextSmall: VCardText,
    VColSmall: VCol
  },

  defaults: {
    VTextField: {
      density: "compact"
    },
    // VInput: {
    //   class: ["pa-0"]
    // },
    VPagination: {
      density: "compact"
    },
    VTable: {
      density: "compact"
    },
    VBtnSmall: {
      style: "text-transform: none; padding: 0.4rem; height: auto;",
      class: ["text-caption"],
      elevation: "0",
    },
    VBtn: {
      density: "comfortable"
    },
    VToolbarTitle: {
      class: ["text-h6"],
    },
    VCardTextSmall: {
      class: ["pa-3"],
    },
    VColSmall: {
      class: ["pa-0"],
    },
  },
});

export default vuetify;
