import Vuex from "vuex";
// import Vue from 'vue'
// import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import order from "./modules/order";
import navigation from "./modules/navigation";
import notification from "./modules/notification";
import userProfile from "./modules/user_profile";
import operator from "./modules/operator";
import role from "./modules/role";
import user from "./modules/user";
import company from "./modules/company";
import field from "./modules/field";
import smsType from "./modules/smstype";
import smsTemplate from "./modules/smstemplate";
import orderSms from "./modules/order_sms";
import brandname from "./modules/brandname";
import mytask from "./modules/mytask";
import campaign from "./modules/campaign";
import product from "./modules/product";
import attribute from "./modules/attribute";
import service from "./modules/service";
import discount from "./modules/discount";
import wallet from "./modules/wallet";
import listoa from "./modules/listoa";
import campaignzalo from "./modules/campaignzalo";
import report from "./modules/report";
import tool from "./modules/tool";
import warning from "./modules/warning";
// Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    navigation,
    order,
    notification,
    userProfile,
    operator,
    role,
    user,
    company,
    field,
    smsType,
    smsTemplate,
    orderSms,
    brandname,
    mytask,
    campaign,
    product,
    attribute,
    service,
    discount,
    wallet,
    listoa,
    campaignzalo,
    report,
    tool,
    warning,
  },
  plugins: [createPersistedState()],
});
