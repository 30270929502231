import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['en', 'vi']

export function setupI18n(options = { locale: 'en' }) {
    const i18n = createI18n(options)
    setI18nLanguage(i18n, options.locale)
    return i18n
}
export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    )

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)
    

    return nextTick()
}
const i18n = createI18n({
    locale: 'vi',
    // legacy: false,
    globalInjection: true,
    datetimeFormats: {
        'vi': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }

        }
    },
    numberFormats: {
        'vi': {
          long: {
            style: 'decimal',
            // style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
            // style: 'currency',
            // currency: 'USD'
          }
        },
      }
})

export { i18n }