import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  listOAs: null,
  link: null,
  pageable: {},
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  listOAs: (state) => state.listOAs,
  link: (state) => state.link,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getListOAs({ commit, getters }, params) {
    console.log("listOA/getListOAs", params);

    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params.companyIds = params.companyIds.map((item) => item.id).join(",");
    }
    // if(params.companyId){
    //   params["companyIds"] = params.companyId
    // }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/zns/oa?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setlistOAs", response.data);
      return response;
    }
    return [];
  },

  async createLisOAs({ commit }, params) {
    try {
      console.log("create ListOAs bending");
      let response = await axios.post(`/zns/oa?`, params);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateListOA({ commit }, params) {
    try {
      console.log("update listOA bending");
      let formData = new FormData();
      formData.append("companyName", params.companyName);
      formData.append("status", params.status);
      let response = await axios.put(`/zns/oa?/${params.id}`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
  async getLinkZNS({ commit }) {
    try {
      const response = await axios.get(`zns/permission/zalo/oa`);
      commit("setLink", response.data);
    } catch (error) {
      // Xử lý lỗi nếu cần
      console.error("Lỗi khi lấy dữ liệu:", error);
    }
  },
};

const mutations = {
  setlistOAs(state, listOAs) {
    state.listOAs = listOAs.data;
    state.pageable = listOAs.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
  setLink(state, data) {
    state.link = data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
