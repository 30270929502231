import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  campaigns: null,
  discountHist: null,
  discountCp: null,
  phones: null,
  totalErrorPhone: null,
  pageable: {},
  pageableCp: {},
  pageableDiscountCp: {},
  search: {
    pageSize: 20,
    // page: 0
  },
  // campaignDetail: {}
};

const getters = {
  campaigns: (state) => state.campaigns,
  discountHist: (state) => state.discountHist,
  discountCp: (state) => state.discountCp,
  phones: (state) => state.phones,
  totalErrorPhone: (state) => state.totalErrorPhone,
  pageable: (state) => state.pageable,
  pageableCp: (state) => state.pageableCp,
  pageableDiscountCp: (state) => state.pageableDiscountCp,
  searchParams: (state) => state.search,
  // campaignDetail: state => state.campaignDetail
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getCampaigns({ commit, getters }, params) {
    console.log("campaign/getCampaigns", params);

    let searchParam = getters.searchParams;

    params["type"] = "sms";

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }

    if (params.status && typeof params.status == "object") {
      params["status"] = params.status.map((item) => item).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}
    const response = await axios.get(
      `/campaigns?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );
    if (response.status == 200) {
      commit("setCampaigns", response.data);
      return response;
    }
    return [];
  },

  async getCampaignDetail({ commit, getters }, params) {
    console.log("campaign/getCampaigns", params);

    try {
      let response = await axios.get(`/campaigns/${params.id}`, {
        params: params,
      });
      if (response.status == 200) {
        // commit('setCampaignDetail', response.data)
        return response.data;
      }
      return [];
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getPhones({ commit, getters }, params) {
    console.log("campaign/getPhones", params);

    let searchParam = getters.searchParams;

    let pageSize =
      params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    let page =
      params["page"] !== undefined &&
      !isNaN(params["page"]) &&
      pageSize !== searchParam.pageSize
        ? 0
        : params.page || 0;

    commit("setPaging", { pageSize: pageSize, page: page });

    let response = await axios.get(
      `/campaigns/${params.id}/phones?pageSize=${pageSize}&pageNumber=${page}`
    );
    if (response.status == 200) {
      commit("setPhones", response.data.phones);
      commit("setTotalErrorPhone", response.data);
      return response.data;
    }
    return [];
  },

  async getHistoryDiscount({ commit }, params) {
    console.log(params);
    const response = await axios.get(`/campaigns/${params.id}/history`);

    if (response.status == 200) {
      commit("setHistoryDiscount", response.data);
      return response.data.data;
    }
    return [];
  },

  async getDiscountCampaign({ commit }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}
    const response = await axios.get(
      `/campaigns/discount?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );
    if (response.status == 200) {
      commit("setDiscountCampaign", response.data);
      return response.data.data;
    }
    return [];
  },

  async createCampaign({ commit }, params) {
    try {
      console.log("create campaign bending");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("brandNameId", params.brandName.id);
      formData.append("walletId", params.wallet.id);
      formData.append("messageContent", params.messageContent);
      formData.append("sendDate", params.sendDate);
      formData.append("excelFile", params.file);
      formData.append("isUnicode", params.isUnicode);
      let response = await axios.post(`/campaigns`, formData, config);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateCampaign({ commit }, params) {
    console.log("paramsss", params);
    try {
      console.log("update campaign bending");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("brandNameId", params.brandName.id);
      formData.append("walletId", params.wallet.id);
      formData.append("messageContent", params.messageContent);
      formData.append("sendDate", params.sendDate);
      formData.append("isUnicode", params.isUnicode);
      if (params.file) {
        formData.append("excelFile", params.file);
      }
      if (params.stepType) {
        formData.append("type", "BACK");
      }
      let response = await axios.put(
        `/campaigns/${params.id}/update`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updatePhone({ commit }, params) {
    try {
      console.log("update Phone bending");
      const formData = {
        phoneNumber: params.phoneNumber,
        sendDate: params.sendDate,
        variable: params.variable,
      };
      let response = await axios.put(
        `/campaigns/phones/${params.id}`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      } else {
        return error.response;
      }
    }
  },

  async updateListPhone({ commit }, params) {
    try {
      console.log("paramsssss", params);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("file", params.file);
      let response = await axios.put(
        `/campaigns/${params.id}/phones`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      } else {
        return error.response;
      }
    }
  },

  async confirmCampaign({ commit }, params) {
    try {
      console.log("update campaign bending");
      let formData = new FormData();
      formData.append("sendDate", params.sendDate);
      let response = await axios.put(
        `/campaigns/${params.id}/confirm`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async deleteCampaign({ commit }, params) {
    console.log("delete Campaign");
    try {
      let response = await axios.delete(`/campaigns/${params.id}`);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async deletePhone({ commit }, params) {
    console.log("delete Phone");
    try {
      let response = await axios.delete(`/campaigns/phones/${params.id}`);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  getLogMessage({ commit }, params) {
    return axios.get(`/campaigns/${params.id}/messages`, {
      params: { ...params.query },
    });
  },

  async cancelCampaign({ commit }, params) {
    console.log("cancel Campaign");
    try {
      let response = await axios.put(`/campaigns/${params.id}/cancel`);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async exportFilePhoneError({ commit, getters }, params) {
    console.log("campaign/exportFilePhoneError", params);
    axios({
      url: "/campaigns/" + params.id + "/export-to-excel",
      method: "GET",
      responseType: "blob",
      params: params,
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = "So_thue_bao_loi.xlsx";
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },

  async downloadFileCp({ commit }, params) {
    let nameFile = "DS_SDT." + params.fileType;
    axios({
      url: `/files/downloads/${nameFile}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = nameFile;
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },
};

const mutations = {
  setCampaigns(state, data) {
    state.campaigns = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageableCp = data.pageable;
  },
  setHistoryDiscount(state, data) {
    state.discountHist = data.data;
  },
  setDiscountCampaign(state, data) {
    state.discountCp = data.data;
    state.pageableDiscountCp = data.pageable;
  },
  setPhones(state, data) {
    state.phones = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageable = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
  },
  setTotalErrorPhone(state, data) {
    state.totalErrorPhone = data.totalErrorPhone;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
