import { createRouter, createWebHashHistory } from "vue-router";
import {
  i18n,
  SUPPORT_LOCALES,
  setI18nLanguage,
  loadLocaleMessages,
} from "@/i18n";
const {
  global: { locale },
} = i18n;

import store from "@/store";

const Login = () =>
  import(/* webpackChunkName: "views/auth/login" */ `@/views/auth/Login.vue`);
const HomePage = () =>
  import(/* webpackChunkName: "views/home-page" */ `@/views/HomePage.vue`);
const Dashboard = () => import(`@/views/dashboard/Dashboard.vue`);
const Operater = () => import(`@/views/operator/Operator.vue`);
const UserAdmin = () => import(`@/views/account/UserAdmin.vue`);
const AccountDefault = () => import(`@/views/account/default.vue`);
const User = () => import(`@/views/account/UserPortal.vue`);
const Companies = () => import(`@/views/company/Company.vue`);
const Brandname = () => import(`@/views/brandname/Brandname.vue`);
const FieldType = () => import(`@/views/fieldtype/fieldtype.vue`);
const SMSType = () => import(`@/views/smstype/SMSType.vue`);
const SmsTemplate = () => import(`@/views/smstemplate/SmsTemplate.vue`);
const OrderSms = () => import(`@/views/order/OrderSms.vue`);
const Role = () => import(`@/views/role/Role.vue`);
const Campaign = () => import(`@/views/campaign/Campaign.vue`);
const MyTask = () => import(`@/views/mytask/MyTask.vue`);
const ForgotPassword = () => import(`@/views/auth/ForgotPassword.vue`);
const Profile = () => import(`@/views/account/UserProfile.vue`);
const Product = () => import(`@/views/product/Product.vue`);
const Attribute = () => import(`@/views/attribute/Attribute.vue`);
const Service = () => import(`@/views/service/Service.vue`);
const Discount = () => import(`@/views/discount/Discount.vue`);
const Wallet = () => import(`@/views/wallet/Wallet.vue`);
const TradeWallet = () => import(`@/views/tradewallet/Tradewallet.vue`);
const ListOA = () => import(`@/views/servicezns/ListOa.vue`);
const CampaignZalo = () => import(`@/views/campaignzalo/CampaignZalo.vue`);
const ZNStemplate = () => import(`@/views/znstemplate/ZnsTemplate.vue`);
const ToolSearchPhone = () => import(`@/views/tool/ToolSearchPhone.vue`);
const Notification = () => import(`@/views/notification/Notification.vue`);
const ChartQuantity = () => import(`@/views/tool/ChartQuantity.vue`);
const ChartTotalQuantity = () => import(`@/views/tool/ChartTotalQuantity.vue`);
const ReportGeneral = () =>
  import(`@/views/report/servicereport/ReportGeneral.vue`);
const ReportDetail = () =>
  import(`@/views/report/servicereport/ReportDetail.vue`);
const ReportQuantity = () =>
  import(`@/views/report/servicereport/ReportQuantity.vue`);
const ReportRevenue = () =>
  import(`@/views/report/controlreport/ReportRevenue.vue`);
const ReportSupplier = () =>
  import(`@/views/report/controlreport/ReportSupplier.vue`);

const routes = [
  {
    path: "/task",
    name: "task",
    component: MyTask,
    meta: {},
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { guest: false },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true },
  },

  {
    path: "/operater",
    name: "operater",
    component: Operater,
    meta: { guest: false },
  },

  {
    path: "/companies",
    name: "companies",
    component: Companies,
    meta: { guest: false },
  },

  {
    path: "/listOA",
    name: "listOA",
    component: ListOA,
    meta: { guest: false },
  },

  {
    path: "/ZNStemplate",
    name: "ZNStemplate",
    component: ZNStemplate,
    meta: { guest: false },
  },

  {
    path: "/brandname",
    name: "brandname",
    component: Brandname,
    meta: { guest: false },
  },

  {
    path: "/field",
    name: "field",
    component: FieldType,
    meta: { guest: false },
  },

  {
    path: "/smstype",
    name: "sms-type",
    component: SMSType,
    meta: { guest: false },
  },

  {
    path: "/smstemplate",
    name: "sms-template",
    component: SmsTemplate,
    meta: { guest: false },
  },

  {
    path: "/orderSms",
    name: "order-sms",
    component: OrderSms,
    meta: { guest: false },
  },

  {
    path: "/role",
    name: "role",
    component: Role,
    meta: { guest: false },
  },

  {
    path: "/campaign",
    name: "campaign",
    component: Campaign,
    meta: { guest: false },
  },

  {
    path: "/attribute",
    name: "attribute",
    component: Attribute,
    meta: { guest: false },
  },

  {
    path: "/service",
    name: "service",
    component: Service,
    meta: { guest: false },
  },
  {
    path: "/discount",
    name: "discount",
    component: Discount,
    meta: { guest: false },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
    meta: { guest: false },
  },
  {
    path: "/tradewallet",
    name: "tradewallet",
    component: TradeWallet,
    meta: { guest: false },
  },
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: { guest: false },
  },
  {
    path: "/campaignzalo",
    name: "campaignzalo",
    component: CampaignZalo,
    meta: { guest: false },
  },
  {
    path: "/",
    name: "notification",
    component: Notification,
    meta: { guest: false },
  },
  {
    path: "/tool",
    meta: { guest: false },
    children: [
      {
        path: "search-phone",
        component: ToolSearchPhone,
        name: "search-phone",
        meta: { guest: false },
      },
      {
        path: "chart-total-quantity",
        component: ChartTotalQuantity,
        name: "chart-total-quantity",
        meta: { guest: false },
      },
      {
        path: "chart-quantity",
        component: ChartQuantity,
        name: "chart-quantity",
        meta: { guest: false },
      },
    ],
  },
  {
    path: "/report",
    meta: { guest: false },
    children: [
      {
        path: "general",
        component: ReportGeneral,
        name: "general",
        meta: { guest: false },
      },
      {
        path: "detail",
        component: ReportDetail,
        name: "detail",
        meta: { guest: false },
      },
      {
        path: "quantity",
        component: ReportQuantity,
        name: "quantity",
        meta: { guest: false },
      },
      {
        path: "revenue",
        component: ReportRevenue,
        name: "revenue",
        meta: { guest: false },
      },
      {
        path: "supplier",
        component: ReportSupplier,
        name: "supplier",
        meta: { guest: false },
      },
    ],
  },
  // {
  //     path: '/mytask',
  //     name: "mytask",
  //     component: MyTask,
  //     meta: { guest: false },
  // },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { guest: true },
  },

  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { guest: false },
  },

  {
    path: "/account",
    component: AccountDefault,
    meta: { guest: false },
    children: [
      {
        path: "userAdmin",
        component: UserAdmin,
        name: "userAdmin",
        meta: { guest: false },
      },
      {
        path: "user",
        component: User,
        name: "user",
        meta: { guest: false },
      },
    ],
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

// authenticating
router.beforeEach((to, from, next) => {
  // if user visist any protected route, then we must ensure the user is authenticated
  if (to.matched.some((record) => !record.meta.guest)) {
    if (store.getters["auth/isAuthenticated"]) {
      next();
      return;
    }
    // if not authenticated, then redirect to login
    // if redirect to reset password ne
    if (to.matched.some((record) => record.name == "reset-password")) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

function setPageTitle(to) {
  const hasMetaTitleString = typeof to.meta.title == "string";
  let newTitle;

  if (!hasMetaTitleString) {
    newTitle = process.env.VUE_APP_TITLE;
  } else {
    newTitle = i18n.global.t(to.meta.title) + " | " + process.env.VUE_APP_TITLE;
  }

  document.title = newTitle;
}

// locale
// navigation guards
router.beforeEach(async (to, from, next) => {
  // lấy tham số locale,từ query string
  let paramsLocale = to.params.locale || locale;
  // Nếu locale không hỗ trợ thì lấy mặc định
  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    paramsLocale = locale;
  }
  // load locale messages
  if (i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale);
  }
  // set i18n language
  setI18nLanguage(i18n, paramsLocale);
  // set page Title
  setPageTitle(to);

  return next();
});

export default router;
