import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  fields: null,
  pageable: {},
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  fields: (state) => state.fields,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getFields({ commit, getters }, params) {
    // console.log('field/getFields', params)

    let searchParam = getters.searchParams;

    // let pageSize = (params['pageSize'] != undefined) ? params.pageSize : searchParam.pageSize
    // let page = (params['page'] != undefined && !isNaN(params['page'])) ? params.page : 0

    // let pageSize =
    //     params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    // let page =
    //     (params["page"] !== undefined && !isNaN(params["page"]) && pageSize !== searchParam.pageSize) ? 0 : params.page || 0;

    // commit('setPaging', { pageSize: pageSize, page: page })
    // if (params.companyIds) {
    //     var listCompanyId = params.companyIds
    //         .map((item) => `companyIds=${item.id}`)
    //         .join("&")
    // }

    // let response = await axios.get(`/sectors?search=${params.searchText || ''}&pageSize=${pageSize}&pageNumber=${page}&status=${params.status || ''}&${listCompanyId || ''}`)

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/sectors?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setFields", response.data);
      return response;
    }
    return [];
  },

  async createField({ commit }, params) {
    try {
      console.log("create Operator bending");
      let response = await axios.post(`/sectors`, params);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateField({ commit }, params) {
    try {
      console.log("update Operator bending");
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("code", params.code);
      formData.append("status", params.status);
      let response = await axios.put(`/sectors/${params.id}`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setFields(state, data) {
    state.fields = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageable = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
