import axios, { formToJSON } from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
    userAdmins: null,
    roles: null,
    permissions: null,
    rolePermission: null,
    pageable: {},
    search: {
        pageSize: 20,
        // page: 0
    }
}

const getters = {
    userAdmins: state => state.userAdmins,
    pageable: state => state.pageable,
    searchParams: state => state.search,
    roles: state => state.roles,
    permissions: state => state.permissions,
    rolePermission: state => state.rolePermission,
}

const actions = {
    /**
     * get list of operaters
     * @param {commit}  
     */
    async getUserAdmins({ commit, getters }, params) {
        console.log('user-admin/getuserAdmins', params)

        let searchParam = getters.searchParams

        let pageSize = (params['pageSize'] != undefined) ? params.pageSize : searchParam.pageSize
        let page = (params['page'] != undefined && !isNaN(params['page'])) ? params.page : 0

        commit('setPaging', { pageSize: pageSize, page: page })

        let response = await axios.get(`/admin/users?search=${params.searchText || ''}&pageSize=${pageSize}&pageNumber=${page}`)
        if (response.status == 200) {
            commit('setUserAdmins', response.data)
            return response.data.data
        }
        return []
    },

    async createUserAdmin({ commit }, params) {
        try {
            console.log("create user-admin bending")
            let response = await axios.post(`/admin/users`, params)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },

    async updateUserAdmin({ commit }, params) {
        try {
            console.log("update user-admin bending")
            let formData = new FormData()
            formData.append('username', params.username)
            formData.append('email', params.email)
            formData.append('password', params.password)
            formData.append('status', params.status)
            // formData.append('roles', params.roles)
            params.roles.forEach((item) => formData.append("roles[]", item))
            let response = await axios.put(`/admin/users/${params.id}`, formData)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }

    },

    async getRoles({ commit, getters }, params) {
        console.log("get user-admin roles bending")
        let searchParam = getters.searchParams;
        let pageSize =
            params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
        let page =
            (params["pageNumber"] !== undefined && !isNaN(params["pageNumber"]) && pageSize !== searchParam.pageSize) ? 0 : params.pageNumber || 0;
            commit('setPaging', { pageSize: pageSize, page: page })
        let response = await axios.get(`/roles?search=${params.search || ''}&pageSize=${pageSize}&pageNumber=${page}`)
        if (response.status == 200) {
            commit('setRoles', response.data)
            return response.data.data
        }
        return []
    },

    async getPermissions({ commit }, params) {
        console.log("get permission bending")
        let response = await axios.get(`/permission`)
        if (response.status == 200) {
            commit('setPermissons', response.data.data)
            return response.data.data
        }
        return []
    },

    async getRolePermission({ commit }, params) {
        console.log("get permission bending")
        let response = await axios.get(`/roles/${params.roleId}/permission`)
        if (response.status == 200) {
            commit('setRolePermission', response.data.data)
            return response.data.data
        }
        return []
    },

    async createRole({ commit }, params) {
        try {
            console.log("create user-admin bending")
            // let formData1 = new FormData()
            // formData.append('name', params.name)
            // formData.append('code', params.code)
            // formData.append('permissionIds', params.permissionIds.map(item=>item.id))
            let formData = {
                name: params.name,
                permissionIds: params.permissionIds.map(item => item.id)
            }
            let response = await axios.post(`/roles`, formData)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },

    async updateRole({ commit }, params) {
        try {
            console.log("create user-admin bending")
            // let formData = new FormData()
            // formData.append('name', params.name)
            // formData.append('code', params.code)
            // formData.append('permissionIds', params.permissionIds.map(item=>item.id))
            let formData = {
                name: params.name,
                permissionIds: params.permissionIds.map(item => item.id)
            }
            let response = await axios.put(`/roles/${params.roleId}`, formData)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },
}

const mutations = {
    setUserAdmins(state, data) {
        state.userAdmins = data.data.map((item, index) => ({
            ...item,
            index: index + 1,
        }));
        state.pageable = data.pageable
    },
    setPaging(state, data) {
        state.search.pageSize = data.pageSize
        // state.search.page = data.page
    },
    setRoles(state, data) {
        state.roles = data.data
        state.pageable = data.pageable
    },
    setPermissons(state, data) {
        state.permissions = data
    },
    setRolePermission(state, data) {
        state.rolePermission = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}