import { createApp } from 'vue'
import App from '@/App.vue'
import axios from 'axios'
import store from '@/store';
import { i18n } from '@/i18n';
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import { abilitiesPlugin } from '@casl/vue';
import ability from './config/ability'

var refreshCount = 0;

// eslint-disable-next-line
axios.defaults.baseURL = config.VUE_APP_API_PREFIX || process.env.VUE_APP_API_PREFIX
axios.defaults.headers['Content-Type'] = `application/json`
// set default bearer token
axios.interceptors.request.use(
    (config) => {
        const token = store.getters['auth/accessToken'];
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.log("Axios request error", error)
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    async (response) => {
        refreshCount = 0;
        console.log('Axios response', response)
        // return response;
        // if(200 === response.status) {
        //     // lấy refresh token được lưu trong store
        //     const token = store.getters['auth/refreshToken'];
        //     try {
        //         let resp = await axios.post('/auth/refreshtoken', {refreshToken: token})

        //         let accessToken = resp.data.accessToken;
        //         let refreshToken = resp.data.refreshToken;
        //         // lưu token trở lại store
        //         store.commit('setToken', accessToken)
        //         store.commit('setRefreshToken', refreshToken)

        //         console.log('refresh token ok. replay request')
        //         // refresh token ok
        //         const { config } = response;
        //         // const originalRequest = config;
        //         return axios.create(config)
        //     } catch(err) {
        //         console.log('refresh token error', err)
        //         router.push({name: 'login'})
        //     }
        //     return
        // }
        return response
    },
    async (error) => {
        console.log('Axios reponse error', error)
        
        const loginPath = '/login';
        const currentPath = router.currentRoute.value.path;
        if (loginPath == currentPath && 401 === error.response.status) {
            return Promise.reject(error);
        }
        
        // nếu lỗi token hết hạn, thì thử refresh token
        if (error.code && error.code == "ERR_NETWORK" || 401 === error.response.status) {
            refreshCount += 1
            if (refreshCount >= 3) {
                refreshCount = 0
                return Promise.reject(error);
            }
            // lấy refresh token được lưu trong store            
            const token = store.getters['auth/refreshToken'];
            try {
                // var configs = {
                //     headers: {
                //       "Access-Control-Allow-Origin": "*",
                //       "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                //       "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                //     },
                //   };
                console.log('trying to refresh token for: ', refreshCount)
                let resp = await axios.post('/auth/refreshtoken', { refreshToken: token })
                console.log('refresh token response:', resp)
                let accessToken = resp.data.accessToken;
                let refreshToken = resp.data.refreshToken;
                // lưu token trở lại store
                store.commit('auth/setToken', accessToken)
                store.commit('auth/setRefreshToken', refreshToken)
                // refresh token ok
                const { config } = error;
                console.log('refresh token ok. replay request', config)
                // const originalRequest = config;
                return axios.request(config)
            } catch (err) {
                return router.push({ name: 'login' })
            }
        }
        // các lỗi khác, trả về lỗi bình thường
        return Promise.reject(error);
    }
);

console.log('i18n', i18n)

// TODO: the next line is added for debugging purposes and should be removed for production build
window.ability = ability
// create vue App
const app = createApp(App)
app
    .use(i18n)
    .use(store)
    .use(router)
    .use(vuetify)
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    // .use(validation)
    .mount('#app')