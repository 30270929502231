<template>
  <component :is="layout">
    <router-view v-model:layout="layout"/>
  </component>
</template>

<script>
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: `App`,
  data() {
    return {
      layout: `div`,
    };
  },
};
</script>