import { defineAbility  } from '@casl/ability'
import store from '@/store';
/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
const rules = store.getters['auth/rules']
const ability = defineAbility((can)=>{
  // can(['read', 'create'], 'home')
  // can(['update', 'delete'], 'Todo', { assignee: 'me' })
})
ability.update(rules)

export default ability

// export default defineAbility((can) => {
//   // can(['read', 'create'], 'home')
//   // can(['update', 'delete'], 'Todo', { assignee: 'me' })
// })
