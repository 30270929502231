import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  services: null,
  categories: null,
  companyService: null,
  productDetail: null,
  pageable: {},
  pageableDirection: {},
  pageableProduct: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  services: (state) => state.services,
  categories: (state) => state.categories,
  companyService: (state) => state.companyService,
  productDetail: (state) => state.productDetail,
  pageable: (state) => state.pageable,
  pageableDirection: (state) => state.pageableDirection,
  pageableProduct: (state) => state.pageableProduct,
  searchParams: (state) => state.search,
};

const actions = {
  async getCategories({ commit, getters }, params) {
    const response = await axios.get(`/categories`, { params: params });

    if (response.status == 200) {
      commit("setCategories", response.data);
      return response.data.data;
    }
    return [];
  },

  async getServices({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    console.log("brandname serch", params, searchParam);

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/services?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setServices", response.data);
      return response.data.data;
    }
    return [];
  },

  async getCompanyService({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/services/${params.id}/companies?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setCompanyService", response.data);
      return response.data.data;
    }
    return [];
  },

  async getProductDetailService({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/services/${params.id}/product_template?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setProductDetailService", response.data);
      return response;
    }
    return [];
  },

  async createCategories({ commit }, params) {
    console.log("params", params);
    try {
      let response = await axios.post(`/categories`, { name: params });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async createServices({ commit }, params) {
    console.log("params", params);
    try {
      let response = await axios.post(`/services`, {
        categoryId: params.serviceGroup.id,
        name: params.name,
        type: params.type,
        status: params.status,
      });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async createCompanyService({ commit }, params) {
    console.log("params", params);
    try {
      let response = await axios.post(`/services/assign`, {
        companyService: params.companyService.map((n) => ({
          serviceId: params.serviceId,
          companyId: n.id,
          isAssigned: n.assigned,
          walletId: n.wallet ? n.wallet.id : null,
        })),
      });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateServices({ commit }, params) {
    console.log("params", params);
    try {
      let response = await axios.put(`/services/${params.id}`, {
        categoryId: params.category.id,
        name: params.name,
        type: params.type,
        status: params.status,
      });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setServices(state, data) {
    state.services = data.data.map((item, index) => ({
      ...item,
    }));
    state.pageable = data.pageable;
  },
  setCategories(state, data) {
    state.categories = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    // state.pageable = data.pageable;
  },
  setCompanyService(state, data) {
    state.companyService = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageableDirection = data.pageable;
  },
  setProductDetailService(state, data) {
    state.productDetail = data.data;
    state.pageableProduct = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
