import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  companies: null,
  serviceCompany: null,
  history: null,
  detail: null,
  pageable: {},
  pageableService: {},
  notificationUrl: null,
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  companies: (state) => state.companies,
  serviceCompany: (state) => state.serviceCompany,
  history: (state) => state.historyCpn,
  detail: (state) => state.detail,
  pageable: (state) => state.pageable,
  pageableService: (state) => state.pageableService,
  searchParams: (state) => state.search,
  notificationUrl: (state) => state.notificationUrl,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getCompanies({ commit, getters }, params) {
    // console.log('company/getCompanies', params)

    let searchParam = getters.searchParams;

    console.log("get company", params, searchParam);

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/companies?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setCompanies", response.data);
      return response;
    }
    return [];
  },

  async getDetail({ commit, getters }, params) {
    const response = await axios.get(`/companies/${params.id}`);

    if (response.status == 200) {
      commit("setDetail", response.data);
      return response.data.data.attachments;
    }
    return [];
  },

  async getServiceCompany({ commit }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/companies/${params.id || params.companyId}/service?search=${search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setServiceCompany", response.data);
      return response;
    }
    return [];
  },

  async createServiceByCompany({ commit }, params) {
    console.log("params", params);
    const bodyData = {
      companyId: params.companyId,
      serviceId: params.companyService.name.id,
      walletId: params.companyService.wallet.id,
      isAssigned: true,
    };
    try {
      let response = await axios.put(
        `/companies/${params.companyId}/assign_service`,
        bodyData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async changeStatusServiceByCompany({ commit }, params) {
    console.log("params", params);
    const bodyData = {
      serviceId: params.serviceId,
      walletId: params.companyService.wallet.id,
      status: params.status,
    };
    try {
      let response = await axios.put(
        `/companies/${params.companyId}/service/status_change`,
        bodyData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async createCompany({ commit }, params) {
    try {
      console.log("create user bending");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("code", params.code);
      formData.append("taxCode", params.taxCode);
      formData.append("email", params.email);
      formData.append("address", params.address);
      formData.append("phone", params.phone);
      if (params.businessFile) {
        formData.append("businessFile", params.businessFile);
      }
      if (params.authorFile) {
        formData.append("authorFile", params.authorFile);
      }
      if (params.registrationFile) {
        formData.append("registrationFile", params.registrationFile);
      }
      if (params.otherFile) {
        formData.append("otherFile", params.otherFile);
      }
      let response = await axios.post(`/companies`, formData, config);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateCompany({ commit }, params) {
    try {
      console.log("update user bending");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("code", params.code);
      formData.append("taxCode", params.taxCode);
      formData.append("email", params.email);
      formData.append("address", params.address);
      formData.append("phone", params.phone);
      if (params.businessFile) {
        formData.append("businessFile", params.businessFile);
      }
      if (params.authorFile) {
        formData.append("authorFile", params.authorFile);
      }
      if (params.registrationFile) {
        formData.append("registrationFile", params.registrationFile);
      }
      if (params.otherFile) {
        formData.append("otherFile", params.otherFile);
      }
      let response = await axios.put(
        `/companies/${params.id}`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async createWallet({ commit }, params) {
    try {
      console.log("parammmmmmmmmmmm", params);
      const dataBody = {};
      dataBody.isPrepaid = params.isPrepaid;
      dataBody.name = params.nameWallet;
      dataBody.moneyLimit = params.amount;
      dataBody.limitStartDate = params.startDate;
      dataBody.limitDurationDays = parseInt(params.numberDate);
      let response = await axios.post(
        `wallets/company/${params.companyId}`,
        dataBody
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async downloadFile({ commit, getters }, params) {
    axios({
      url: "/files/downloads/" + params.name,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = params.displayName;
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },

  async getHistory({ commit, getters }, params) {
    // console.log('history/getHistory', params);

    let searchParam = getters.searchParams;

    let pageSize =
      params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    let page =
      params["page"] != undefined && !isNaN(params["page"]) ? params.page : 0;

    // console.log("searchParam",searchParam);
    // console.log("pageSize", pageSize);
    // console.log("page", page);

    const response = await axios.get(
      `/status-histories/${params.id}?type=${params.type}&pageNumber=${page}&pageSize=${pageSize}`
    );
    if (response.status == 200) {
      console.log("hist", response.data);
      commit("setHistory", response.data);
      return response.data.data;
    }
    return [];
  },
  async getnotificationUrl({ commit }, params) {
    try {
      if (params.companyId) {
        params["companyIds"] = params.companyId;
      }
      const response = await axios.get(`/notification-url`, { params: params });
      if (response.status === 200) {
        commit('setNotificationUrl', response.data);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching notification URL:', error);
      return [];
    }
  },

  async createnotificationUrl({ commit }, params) {
    const data = {
      companyId: params.company.id,
      url: params.url
    };

    try {
      const response = await axios.post(`/notification-url`, data);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updatenotificationUrl({ commit }, params) {
    console.log("params: ", params);
    const data = {
      companyId: params.company.id,
      url: params.url
    };
    try {
      const response = await axios.put(`/notification-url/${params.id}`, data);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setCompanies(state, data) {
    state.companies = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageable = data.pageable;
  },
  setHistory(state, data) {
    state.historyCpn = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageableHist = data.pageable;
  },
  setDetail(state, data) {
    state.detail = data.data.attachments.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
  },
  setServiceCompany(state, data) {
    state.serviceCompany = data.data;
    state.pageableService = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
  setNotificationUrl(state, data) {
    state.notificationUrl = data.data
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
