import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  discounts: null,
  products: null,
  brandnameDiscount: null,
  pageable: {},
  pageableProduct: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  discounts: (state) => state.discounts,
  products: (state) => state.products,
  brandnameDiscount: (state) => state.brandnameDiscount,
  pageableProduct: (state) => state.pageableProduct,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  async getDiscounts({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }

    if (params.serviceIds) {
      params["serviceIds"] = params.serviceIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/discounts?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setDiscounts", response.data);
      return response;
    }
    return [];
  },

  async getBrandnameDiscount({ commit, getters }, params) {

    const response = await axios.get(`/brand-names/products?`, {
      params: params,
    });

    if (response.status == 200) {
      commit("setBrandnameDiscount", response.data);
      return response;
    }
    return [];
  },

  async getProductList({ commit, getters }, params) {
    if (params.ids) {
      params["ids"] = params.ids.map((id) => id).join(",");
    }
    const response = await axios.get(`/product_templates/products/list`, {
      params: params,
    });

    if (response.status == 200) {
      return response.data.data;
    }
    return [];
  },

  async getProductTemplate({ commit }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/product_templates/products?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setProductTemplate", response.data);
      return response.data.data;
    }
    return [];
  },

  async createDiscount({ commit }, params) {
    try {
      console.log("create discount bending: ", params);
      const listProduct = params.product.map((i) => ({
        productId: i.id,
        brandNameId: i.brandNameID ? i.brandNameID.id : null,
        discountPrice: i.discountPrice ? i.discountPrice : 0,
      }));
      console.log("listProduct", listProduct);
      const formData = {
        companyId: params.company.id,
        serviceId: params.service.id,
        productInfos: listProduct,
        startDate: params.startDate,
        endDate: params.endDate,
      };
      let response = await axios.post(`/discounts`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateDiscount({ commit }, params) {
    console.log("update discount bending: ", params);
    // const listProduct = [];

    // if (Array.isArray(params.product)) {
    //   listProduct.push(...params.product.map((item) => item.id));
    // } else if (typeof params.product === "object" && params.product !== null) {
    //   listProduct.push(params.product.id);
    // }
    const listProduct = [
      {
        productId: params.product.id,
        brandNameId: params.brandName ? params.brandName.id : null,
        discountPrice: params.discountPrice ? params.discountPrice : null,
      },
    ];
    try {
      // [params.product.id]
      const formData = {
        companyId: params.company.id,
        serviceId: params.service.id,
        productInfos: listProduct,
        startDate: params.startDate,
        endDate: params.endDate,
      };
      let response = await axios.put(`/discounts/${params.id}`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async exportDiscount({ commit }, params) {
    console.log("params", params);
    const response = await axios.get(`/discounts/export-discount`, {
      params: params,
      responseType: "blob",
    });

    if (response.status == 200) {
      // return response
      var blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      var url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      var link = document.createElement("a");
      link.href = url;
      link.download = "Danh_sach_chiet_khau.xlsx";
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    }
    return [];
  },
  async updateDiscountExcel({ commit }, params) {
    console.log("params", params);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("update SmsTemplate bending");
      const formData = new FormData();
      if (params) {
        formData.append("file", params);
      }
      const response = await axios.put(
        `/discounts/update-discount`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setDiscounts(state, data) {
    state.discounts = data.data;
    state.pageable = data.pageable;
  },
  setProductTemplate(state, data) {
    state.products = data.data;
    state.pageableProduct = data.pageable;
  },
  setBrandnameDiscount(state, data) {
    state.brandnameDiscount = data;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
