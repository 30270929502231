import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
    smstemplates: null,
    smsTemplateWithBrandname: null,
    file: null,
    pageable: {},
    search: {
        pageSize: 20,
        // page: 0
    }
}

const getters = {
    smstemplates: state => state.smstemplates,
    file: (state) => state.file,
    pageable: state => state.pageable,
    searchParams: state => state.search,
    smsTemplateWithBrandname: state => state.smsTemplateWithBrandname
}

const actions = {
    /**
     * get list of operaters
     * @param {commit}  
     */
    async getSmsTemplates({ commit, getters }, params) {
        console.log('sms/getSmsTemplate', params)
        let formData = new FormData();

        let searchParam = getters.searchParams

        params['pageSize'] = params['pageSize'] === undefined ? searchParam.pageSize : params['pageSize']
        params['pageNumber'] = params['pageNumber'] === undefined ? searchParam.pageNumber : params['pageNumber']

        commit("setPaging", { pageSize: params['pageSize'], page: params['pageNumber'] });

        if (params.companyIds && params.companyIds.length) {
            params['companyIds'] = params.companyIds.map((item) => item.id).join(',');
        }
        // dùng để xử lý khi search chứa ký tự đặc biệt
        // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array                
        const search = params.search
        //xóa key search trong params và mã hóa trên đường dẫn
        delete params.search //?search=${search ? encodeURIComponent(search) : ''}

        const response = await axios.get(`/message-forms?search=${search ? encodeURIComponent(search) : ''}`, { params: params })

        if (response.status == 200) {
            commit('setSmsTemplates', response.data)
            return response
        }
        return []
    },

    async getSmsTemplateWithBrandname({ commit, getters }, params) {
        console.log('sms/getSmsTemplate', params)

        let searchParam = getters.searchParams

        let pageSize = (params['pageSize'] != undefined) ? params.pageSize : searchParam.pageSize
        let page = (params['page'] != undefined && !isNaN(params['page'])) ? params.page : 0

        commit('setPaging', { pageSize: pageSize, page: page })

        let response = await axios.get(`/message-forms/brand-names?brandNameId=${params.brandNameId || ''}&search=${params.searchText || ''}`)
        if (response.status == 200) {
            commit('setSmsTemplatesWithBrandname', response.data)
            return response.data.data
        }
        return []
    },

    async getFile({ commit }, params) {
        const response = await axios.get(`/message-forms/${params.id}`);

        console.log("file", response.data.data.attachments.attachment);

        if (response.status == 200) {
            // console.log("detail-hihi", response.data.data.attachments);
            commit("setFile", response.data);
            return response.data.data.attachments;
        }
        return [];
    },

    async createSmsTemplate({ commit }, params) {
        try {
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            console.log("create SmsTemplate bending")
            let formData = new FormData()
            formData.append('name', params.name)
            formData.append('content', params.content)
            formData.append('brandNameId', params.brandName.id || params.brandName)
            if (params.files) {
                formData.append("file", params.files);
            }
            let response = await axios.post(`/message-forms`, formData, config)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },

    async updateSmsTemplate({ commit }, params) {
        try {
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            console.log("update SmsTemplate bending")
            let formData = new FormData()
            formData.append('name', params.name)
            formData.append('content', params.content)
            formData.append('brandNameId', params.brandName.id)
            if (params.files) {
                formData.append("file", params.files);
            }
            let response = await axios.put(`/message-forms/${params.id}`, formData, config)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },

    async deleteSmsTemplate({ commit }, params) {
        console.log("delete SmsTemplate bending")
        // let response = await axios.delete(`/message-forms/${params.id}`)
        // return response
        try {
            let response = await axios.delete(`/message-forms/${params.id}`)
            return response;
        } catch (error) {
            if (
                ListViewMixin.methods.isError(error.response.status)
            ) {
                return { status: error.response.status, errors: error.response.data };
            }
        }
    }
}

const mutations = {
    setSmsTemplates(state, data) {
        state.smstemplates = data.data.map((item, index) => ({
            ...item,
            index: index + 1,
        }));
        state.pageable = data.pageable
    },
    setSmsTemplatesWithBrandname(state, data) {
        state.smsTemplateWithBrandname = data.data.map((item, index) => ({
            ...item,
            index: index + 1,
        }));
    },
    setFile(state, data) {
        state.file = data.data.attachments
    },
    setPaging(state, data) {
        state.search.pageSize = data.pageSize
        // state.search.page = data.page
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}