import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  brandname: null,
  connectors: null,
  file: null,
  pageable: {},
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  brandname: (state) => state.brandname,
  connectors: (state) => state.connectors,
  file: (state) => state.file,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  async getBrandName({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    console.log("brandname serch", params, searchParam);

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params.companyIds = params.companyIds.map((item) => item.id).join(",");
    }
    if (params.status && typeof params.status == "object") {
      params["status"] = params.status.map((item) => item).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/brand-names?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setBrandName", response.data);
      return response;
    }
    return [];
  },
  async getFile({ commit, getters }, params) {
    // console.log("params", params);
    const response = await axios.get(`/brand-names/${params.id}`);

    if (response.status == 200) {
      console.log("file", response.data);
      console.log("detail-hihi", response.data.data.objectAttachment);
      commit("setFile", response.data);
      return response.data.data.objectAttachment;
    }
    return [];
  },

  async createBrandName({ commit }, params) {
    console.log("params", params);
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("companyId", params.company.id);
      formData.append("messageTypeId", params.messageType.id);
      params.brandNameConfig.forEach((config, index) => {
        formData.append(
          `brandNameConfigs[${index}].homeNetworkId`,
          config.homeNetWork.id
        );
        formData.append(
          `brandNameConfigs[${index}].smsConnectorId`,
          config.smsConnector.id
        );
        formData.append(
          `brandNameConfigs[${index}].sectorId`,
          config.sector.id
        );
      });
      if (params.file1) {
        formData.append("files", params.file1);
      }
      if (params.file2) {
        formData.append("files", params.file2);
      }
      if (params.file3) {
        formData.append("files", params.file3);
      }
      if (params.file4) {
        formData.append("files", params.file4);
      }
      if (params.file5) {
        formData.append("files", params.file5);
      }
      let response = await axios.post(`/brand-names`, formData, config);
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getConnector({ commit }, params) {
    try {
      let response = await axios.get(`/brand-names/connector`);
      if (response.status == 200) {
        commit("setConnector", response.data);
        return response;
      }
      return [];
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateExtend({ commit }, params) {
    console.log("params", params);
    try {
      let response = await axios.put(`/brand-names/renew/${params.id}`);
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateBrandname({ commit }, params) {
    console.log("params", params);
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("companyId", params.company.id);
      formData.append("messageTypeId", params.messageType.id);
      params.brandNameConfig.forEach((config, index) => {
        formData.append(
          `brandNameConfigs[${index}].homeNetworkId`,
          config.homeNetWork.id
        );
        formData.append(
          `brandNameConfigs[${index}].smsConnectorId`,
          config.smsConnector.id
        );
        formData.append(
          `brandNameConfigs[${index}].sectorId`,
          config.sector.id
        );
      });
      if (params.file1) {
        formData.append("files", params.file1);
      }
      if (params.file2) {
        formData.append("files", params.file2);
      }
      if (params.file3) {
        formData.append("files", params.file3);
      }
      if (params.file4) {
        formData.append("files", params.file4);
      }
      if (params.file5) {
        formData.append("files", params.file5);
      }
      let response = await axios.put(
        `/brand-names/${params.id}`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async deleteBrandname({ commit }, params) {
    // const response = await axios.delete(`/brand-names/${params.id}`)
    // return response
    try {
      const response = await axios.delete(`/brand-names/${params.id}`);
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async downloadFileBr({ commit }, params) {
    axios({
      url: "/files/downloads/1685938328176_Th%C3%AAm%20m%E1%BB%9Bi%20%C4%91%C6%A1n%20h%C3%A0ng.png",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = params.displayName;
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },
};

const mutations = {
  setBrandName(state, data) {
    state.brandname = data.data
    state.pageable = data.pageable;
  },
  setFile(state, data) {
    state.file = data.data.objectAttachment;
  },
  setConnector(state, data) {
    state.connectors = data.data;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
