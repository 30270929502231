import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
    operators: null,
    pageable: {},
    search: {
        pageSize: 20,
        // page: 0
    }
}

const getters = {
    operators: state => state.operators,
    pageable: state => state.pageable,
    searchParams: state => state.search
}

const actions = {
    /**
     * get list of operaters
     * @param {commit}  
     */
    async getOperators({ commit, getters }, params) {
        console.log('operator/getOperators', params)

        let searchParam = getters.searchParams

        params['pageSize'] = params['pageSize'] === undefined ? searchParam.pageSize : params['pageSize']
        params['pageNumber'] = params['pageNumber'] === undefined ? searchParam.pageNumber : params['pageNumber']

        commit("setPaging", { pageSize: params['pageSize'], page: params['pageNumber'] });

        if (params.companyIds && params.companyIds.length) {
            params['companyIds'] = params.companyIds.map((item) => item.id).join(',');
        }
        // dùng để xử lý khi search chứa ký tự đặc biệt
        // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array                
        const search = params.search
        //xóa key search trong params và mã hóa trên đường dẫn
        delete params.search //?search=${search ? encodeURIComponent(search) : ''}

        const response = await axios.get(`/home-network?search=${search ? encodeURIComponent(search) : ''}`, { params: params })

        if (response.status == 200) {
            commit('setOperators', response.data)
            return response
        }
        return []
    },

    async createOperator({ commit }, params) {
        try {
            console.log("create Operator bending")
            let response = await axios.post(`/home-network`, params)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    },

    async updateOperator({ commit }, params) {
        try {
            console.log("update Operator bending")
            let formData = new FormData()
            formData.append('name', params.name)
            formData.append('status', params.status)
            let response = await axios.put(`/home-network/${params.id}`, formData)
            return response
        } catch (error) {
            if (ListViewMixin.methods.isError(error.response.status)) {
                return { status: error.response.status, errors: error.response.data }
            }
        }
    }
}

const mutations = {
    setOperators(state, data) {
        state.operators = data.data.map((item, index) => ({
            ...item,
            index: index + 1,
        }));
        state.pageable = data.pageable
    },
    setPaging(state, data) {
        state.search.pageSize = data.pageSize
        // state.search.page = data.page
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}