import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  attributes: null,
  pageable: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  attributes: (state) => state.attributes,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  async getAttributes({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params?.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/attributes?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setAttributes", response.data);
      return response;
    }
    return [];
  },

  async createAttribute({ commit }, params) {
    console.log("params", params);
    if (params.value) {
      params.values.push({
        name: params.value,
      });
    }
    try {
      let response = await axios.post(`/attributes`, {
        name: params.name || params,
        values: params.values || [],
      });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateAttribute({ commit }, params) {
    console.log("paramssss", params);
    if(params.value){
      params.values.push({
        id: null,
        name: params.value
      })
    }
    try {
      let response = await axios.put(`/attributes/${params.id}`, {
        name: params.name,
        values: params.values,
      });
      return response;
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setAttributes(state, data) {
    state.attributes = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageable = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
