import axios, { formToJSON } from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  products: null,
  attributes: null,
  attributeValues: null,
  productAttributes: null,
  productAttributeValues: null,
  services: null,
  productVarials: null,
  pageable: {},
  pageableProduct: {},
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  products: (state) => state.products,
  attributes: (state) => state.attributes,
  attributeValues: (state) => state.attributeValues,
  productAttributes: (state) => state.productAttributes,
  productAttributeValues: (state) => state.productAttributeValues,
  services: (state) => state.services,
  productVarials: (state) => state.productVarials,
  pageable: (state) => state.pageable,
  pageableProdcut: (state) => state.pageableProduct,
  searchParams: (state) => state.search,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getProducts({ commit, getters }, params) {
    console.log("products/getProducts", params);

    let searchParam = getters.searchParams;

    // let pageSize =
    //   params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    // let page =
    //   params["page"] != undefined && !isNaN(params["page"]) ? params.page : 0;

    // commit("setPaging", { pageSize: pageSize, page: page });

    // const fixdata = {data:[{id:1,name:'Thẻ cào', price:200000, service:{id:1,name:'Mua thẻ cào'}, updateAt:"2023-07-25T04:20:27.840384"}]}
    // commit('setProducts', fixdata)
    // let response = await axios.get(`/product_templates?search=${params.searchText || ''}&pageSize=${pageSize}&pageNumber=${page}`)
    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/product_templates?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );
    if (response.status == 200) {
      commit("setProducts", response.data);
      return response.data.data;
    }
    return [];
  },

  async getProductAttributeValues({ commit, getters }, params) {
    console.log(
      "lấy giá trị của thuộc tính theo sản phẩm để cấu hình: ",
      params
    );

    let searchParam = getters.searchParams;

    let pageSize =
      params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    let page =
      params["page"] != undefined && !isNaN(params["page"]) ? params.page : 0;

    commit("setPaging", { pageSize: pageSize, page: page });

    // const fixdata = {data:[{id:1,name:'10k', extraPrice:10000},{id:2,name:'20k', extraPrice:20000}]}
    // commit('setProductAttributeValue', fixdata)
    let response = await axios.get(
      `/product_templates/${params.productId}/attributes/${
        params.attributeId
      }/values?search=${
        params.searchText || ""
      }&pageSize=${pageSize}&pageNumber=${page}`
    );
    if (response.status == 200) {
      commit("setProductAttributeValue", response.data);
      return response.data.data;
    }
    return [];
  },

  async updateProductAttributeValues({ commit }, params) {
    try {
      console.log("cập nhật giá trị của Attribute value", params);
      let response = await axios.put(
        `/product_templates/${params.productId}/attributes/${params.attributeId}/config_value`,
        params.params
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async importExcelProductAttribute({ commit }, params) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("update SmsTemplate bending");
      const formData = new FormData();
      if (params) {
        formData.append("file", params.file);
      }
      const response = await axios.post(
        `/product_templates/${params.id}/import-to-excel-variant`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getProductVarial({ commit, getters }, params) {
    console.log("products/getProductVarial", params);

    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    let response = await axios.get(
      `/product_templates/${params.id}/products?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );
    if (response.status == 200) {
      commit("setProductVarials", response.data);
      return response;
    }
    return [];
  },

  async getAttributes({ commit, getters }, params) {
    console.log("products/getAttributes", params);

    let searchParam = getters.searchParams;

    let pageSize =
      params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    let page =
      params["page"] != undefined && !isNaN(params["page"]) ? params.page : 0;

    commit("setPaging", { pageSize: pageSize, page: page });

    // const fixdata = {data:[
    //     {id:1,name:'Nhà mạng', values:[{id:1,name:'VTT'},{id:2,name:'MBF'},{id:3,name:'VNP'}]},
    //     {id:2,name:'Mệnh giá', values:[{id:4,name:'10k'},{id:5,name:'20k'},{id:6,name:'50k'}]},
    //     {id:3,name:'Màu sắc', values:[{id:7,name:'xanh'},{id:8,name:'đỏ'},{id:9,name:'xám'}]}
    // ]}
    // const fixdata1 = {data:[
    //     {id:1,name:'Nhà mạng', values:[{id:1,name:'VTT'},{id:2,name:'MBF'}]},
    //     {id:2,name:'Mệnh giá', values:[{id:4,name:'10k'},{id:5,name:'20k'}]}
    // ]}
    // if (params.id) {
    //     commit('setAttributes', fixdata)
    //     commit('setAttributeValues', fixdata)
    // } else {
    //     commit('setProductAttributes', fixdata1)
    // }
    let response = await axios.get(
      `/product_templates/attributes?search=${
        params.searchText || ""
      }&pageSize=${pageSize}&pageNumber=${page}`,
      { params: { id: params.id } }
    );
    if (response.status == 200) {
      if (params.id) {
        commit("setProductAttributes", response.data);
      } else {
        commit("setAttributes", response.data);
        commit("setAttributeValues", response.data);
        commit("setProductAttributes", { data: [] });
      }
      return response.data.data;
    }
    return [];
  },

  async createProduct({ commit }, params) {
    try {
      console.log("create product bending: ", params);
      const formData = {
        name: params.name,
        originPrice: parseInt(params.originPrice),
        serviceId: params.service != null ? params.service.id : null,
        attributes: params.attributes != null ? params.attributes : null,
      };
      let response = await axios.post(`/product_templates`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateProduct({ commit }, params) {
    try {
      console.log("update product bending", params);
      const formData = {
        name: params.name,
        originPrice: parseInt(params.originPrice),
        serviceId: params.service != null ? params.service.id : null,
        attributes: params.attributes != null ? params.attributes : null,
      };
      let response = await axios.put(
        `/product_templates/${params.id}`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateTotalPriceProduct({ commit }, params) {
    try {
      const formData = {
        price: params.totalPrice,
      };
      let response = await axios.put(
        `/product_templates/products/${params.id}`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
  async exportproductVarials({ commit }, params) {
    console.log("params", params);
    const response = await axios.get(
      `/product_templates/${params.productVarialIds}/export-to-excel-variant`,
      {
        params: params,
        responseType: "blob",
      }
    );

    if (response.status == 200) {
      // return response
      var blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      var url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      var link = document.createElement("a");
      link.href = url;
      link.download = "Chi_tiet_san_pham.xlsx";
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    }
    return [];
  },
  async updateProductVarialsExcel({ commit }, params) {
    console.log("params", params);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("update SmsTemplate bending");
      const formData = new FormData();
      if (params) {
        formData.append("file", params);
      }
      const response = await axios.put(
        `/product_templates${params.id}/import-to-excel-variant`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setProducts(state, data) {
    state.products = data.data;
    state.pageable = data.pageable;
  },
  setProductVarials(state, data) {
    state.productVarials = data.data;
    state.pageableProduct = data.pageable;
  },
  setAttributes(state, data) {
    state.attributes = data.data.map((item, index) => ({
      id: item.id,
      name: item.name,
    }));
  },
  setAttributeValues(state, data) {
    var danhSachGop = data.data.reduce(function (tong, obj) {
      var values = obj.values.map((item) => ({
        attributeId: obj.id,
        ...item,
      }));
      return tong.concat(values);
    }, []);
    state.attributeValues = danhSachGop;
  },
  setProductAttributes(state, data) {
    state.productAttributes = data.data.map((item, index) => ({
      id: item.id,
      name: item.name,
      object: {
        id: item.id,
        name: item.name,
      },
      values: item.values,
    }));
  },
  setProductAttributeValue(state, data) {
    state.productAttributeValues = data.data;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
