/* eslint-disable */
import axios from 'axios';
import router from "@/router"
import { i18n } from '@/i18n'
import ListViewMixin from '@/mixins/ListViewMixin';
// import ability from  "@/config/ability"

const state = {
    mytasks: [],
    modelTypes: null,
    pageable: {},
    search: {
        pageSize: 20,
        // page: 0
    },
};

const getters = {
    mytasks: state => state.mytasks,
    modelTypes: state => state.modelTypes,
    pageable: (state) => state.pageable,
    searchParams: (state) => state.search,
};

const actions = {

    // async getMyTasks({ commit, getters }, params) {
    //     console.log('operator/getOperators', params)       

    //     let response = await axios.get(`/tasks/model-type`)
    //     var mytaskModel = []        
    //     if(response.status == 200) {
    //         let modelTypes = response.data.data
    //         commit('setModelType', modelTypes)
    //         for (let index = 0; index < modelTypes.length; index++) {
    //             const type = modelTypes[index];
    //             let response1 = await axios.get(`/tasks?type=${type}`)
    //             if(response1.status == 200) {
    //                mytaskModel.push(response1.data.data)                     
    //             } 
    //         }
    //         commit('setMytasks', mytaskModel)            
    //     }
    //     return []
    // },

    async getModelType({ commit, getters }, params) {

        let response = await axios.get(`/tasks/model-type`)
        if (response.status == 200) {
            let modelTypes = response.data.data
            commit('setModelType', modelTypes)
            return modelTypes
        }
        return []
    },

    async getMyTasks({ commit, getters }, params) {
        console.log('company/getCompanies', params)

        let searchParam = getters.searchParams;

        params['pageSize'] = params['pageSize'] === undefined ? searchParam.pageSize : params['pageSize']
        params['pageNumber'] = params['pageNumber'] === undefined ? searchParam.pageNumber : params['pageNumber']

        commit("setPaging", { pageSize: params['pageSize'], page: params['pageNumber'] });

        if (params.companyIds && params.companyIds.length) {
            params['companyIds'] = params.companyIds.map((item) => item.id).join(',');
        }

        if (params.modelType) {
            params['modelType'] = params.modelType.map((item) => item).join(',')
        }

        if (params.status) {
            params['status'] = params.status.map((item) => item).join(',')
        }
        // dùng để xử lý khi search chứa ký tự đặc biệt
        // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array                
        const search = params.search
        //xóa key search trong params và mã hóa trên đường dẫn
        delete params.search //?search=${search ? encodeURIComponent(search) : ''}

        const response = await axios.get(`/tasks?search=${search ? encodeURIComponent(search) : ''}`, { params: params })

        if (response.status == 200) {
            commit("setMytasks", response.data);
            // console.log('company',response.data.data);
            return response;
        }
        return [];
    },

    async sendApproval({ commit }, params) {
        try {
            let formData = new FormData();
            formData.append("objectId", params.objectId);
            formData.append("objectType", params.objectType);
            formData.append("status", params.status);
            let response = await axios.post(`/tasks/send-approval`, formData);
            return response;
        } catch (error) {
            if (
                error.response &&
                (ListViewMixin.methods.isError(error.response.status))
            ) {
                return { status: error.response.status, errors: error.response.data };
            }
        }
    },

    async acceptApproval({ commit }, params) {
        try {
            let formData = new FormData();
            // formData.append("objectId", params.objectId);
            // formData.append("objectType", params.objectType);
            formData.append("status", "APPROVED");
            formData.append("conclusion", params.conclusion);
            if (params.overdueDate) {
                formData.append("endDate", params.overdueDate)
            }
            let response = await axios.post(`/tasks/${params.taskId}/approval`, formData);
            return response;
        } catch (error) {
            if (
                error.response &&
                (ListViewMixin.methods.isError(error.response.status))
            ) {
                return { status: error.response.status, errors: error.response.data };
            }
        }
    },

    async refuseApproval({ commit }, params) {
        try {
            let formData = new FormData();
            formData.append("objectId", params.objectId);
            formData.append("objectType", params.objectType);
            formData.append("status", "REJECT");
            formData.append("conclusion", params.conclusion);
            let response = await axios.post(`/tasks/${params.taskId}/approval`, formData);
            return response;
        } catch (error) {
            if (
                error.response &&
                (ListViewMixin.methods.isError(error.response.status))
            ) {
                return { status: error.response.status, errors: error.response.data };
            }
        }
    },
};

const mutations = {
    setMytasks(state, mytasks) {
        state.mytasks = mytasks.data
        state.pageable = mytasks.pageable
    },
    setModelType(state, modelTypes) {
        state.modelTypes = modelTypes
    },
    setPaging(state, data) {
        state.search.pageSize = data.pageSize;
        // state.search.page = data.page
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};