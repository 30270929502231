import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  toolPhones: null,
  pageablePhone: {},
  callBackParams: null,
  search: {
    pageSize: 20,
  },
};

const getters = {
  toolPhones: (state) => state.toolPhones,
  pageablePhone: (state) => state.pageablePhone,
  callBackParams: (state) => state.callBackParams,
  searchParams: (state) => state.search,
};

const actions = {
  async getTools({ commit, getters }, params) {
    console.log("paramsssss", params);
    try {
      const dataParams = {};
      if (params.typePage && params.typePage == "PAGE") {
        if (params.dataPage.companyIds) {
          dataParams.companyIds = params.dataPage.companyIds;
        }
        if (params.dataPage.brandNameIds) {
          dataParams.brandNameIds = params.dataPage.brandNameIds;
        }
        if (params.dataPage.phoneNumber) {
          dataParams.phoneNumber = params.dataPage.phoneNumber;
        }
        if (params.dataPage.messageTypeIds) {
          dataParams.messageTypeIds = params.dataPage.messageTypeIds;
        }
        if (params.dataPage.oaIds) {
          dataParams.oaIds = params.dataPage.oaIds;
        }
        if (params.dataPage.templateIds) {
          dataParams.templateIds = params.dataPage.templateIds;
        }
        if (params.dataPage.sectorIds) {
          dataParams.sectorIds = params.dataPage.sectorIds;
        }
        if (params.dataPage.status) {
          dataParams.status = params.dataPage.status;
        }
        if (params.dataPage.startDate) {
          dataParams.startDate = params.dataPage.startDate;
        }
        if (params.dataPage.endDate) {
          dataParams.endDate = params.dataPage.endDate;
        }
        if (params.dataPage.serviceType) {
          dataParams.serviceType = params.dataPage.serviceType;
        }
      } else {
        if (params.companyIds) {
          dataParams.companyIds = [params.companyIds];
        }
        if (params.brandNameIds) {
          dataParams.brandNameIds = [params.brandNameIds];
        }
        if (params.phoneNumber) {
          dataParams.phoneNumber = params.phoneNumber;
        }
        if (params.messageTypeIds) {
          dataParams.messageTypeIds = [params.messageTypeIds];
        }
        if (params.sectorIds) {
          dataParams.sectorIds = [params.sectorIds];
        }
        if (params.oaIds) {
          dataParams.oaIds = [params.oaIds];
        }
        if (params.templateIds) {
          dataParams.templateIds = [params.templateIds];
        }
        if (params.status) {
          dataParams.status = params.status.map((item) => item);
        }
        if (params.startDate) {
          dataParams.startDate = params.startDate;
        }
        if (params.endDate) {
          dataParams.endDate = params.endDate;
        }
        if (params.serviceType) {
          dataParams.serviceType = params.serviceType;
        }
      }
      dataParams.pageNumber = params.pageNumber;
      dataParams.pageSize = params.pageSize ? Number(params.pageSize) : 20;
      commit("setPaging", {
        pageSize: dataParams.pageSize,
        page: dataParams.pageNumber,
      });
      commit("setCallback", {
        dataParams,
      });
      let response;
      switch (params.typeTool) {
        case "PHONE":
          response = await axios.post(`/tools/search_phones`, dataParams);
          dataParams.typeTool = "PHONE";
          commit("setToolPhones", response.data);
          break;
        default:
          break;
      }
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setToolPhones(state, data) {
    state.toolPhones = data.data;
    state.pageablePhone = data.pageable;
  },
  setCallback(state, data) {
    state.callBackParams = data.dataParams;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
