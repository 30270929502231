import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  warnings: null,
  warningDetail: null,
  pageable: {},
  pageableDetail: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  warnings: (state) => state.warnings,
  warningDetail: (state) => state.warningDetail,
  pageable: (state) => state.pageable,
  pageableDetail: (state) => state.pageableDetail,
  searchParams: (state) => state.search,
};

const actions = {
  async getWarnings({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/wallets/warnings?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setWarnings", response.data);
      return response;
    }
    return [];
  },

  async getWarningDetail({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/wallets/warnings/detail?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setWarningDetail", response.data);
      return response;
    }
    return [];
  },
};

const mutations = {
  setWarnings(state, data) {
    state.warnings = data.data;
    state.pageable = data.pageable;
  },
  setWarningDetail(state, data) {
    state.warningDetail = data.data;
    state.pageableDetail = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
