import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  wallets: null,
  historyWallet: null,
  walletCompany: null,
  pageable: {},
  pageableHistory: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  wallets: (state) => state.wallets,
  historyWallet: (state) => state.historyWallet,
  walletCompany: (state) => state.walletCompany,
  pageableHistory: (state) => state.pageableHistory,
  pageable: (state) => state.pageable,
  searchParams: (state) => state.search,
};

const actions = {
  async getWallets({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/wallets?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setWallets", response.data);
      return response;
    }
    return [];
  },

  async getHistoryWallet({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/wallets/transactions?search=${
        search ? encodeURIComponent(search) : ""
      }`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setHistoryWallet", response.data);
      return response.data.data;
    }
    return [];
  },

  async tradeWallet({ commit }, params) {
    const id = params.id ? params.id : params.walletNumber.id;
    try {
      const formData = {
        type: params.type,
        amount: params.amount,
        description: params.description,
      };
      let response = await axios.put(
        `/wallets/${id}/transactions/balance`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateNameWallet({ commit }, params) {
    console.log("paramsssssssss", params);
    try {
      const formData = {
        name: params.name,
        moneyLimit: params.moneyLimit,
        limitStartDate: params.limitStartDate,
        limitDurationDays: parseInt(params.limitDurationDays),
      };
      let response = await axios.put(`/wallets/${params.id}`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async getWalletCompany({ commit }, params) {
    try {
      let response = await axios.get(`/wallets/company/${params.companyId}?`, {
        params: params,
      });
      if (response.status == 200) {
        commit("setWalletCompany", response.data);
        return response;
      }
      return [];
    } catch (error) {
      if (
        // error.response &&
        // (error.response.status == 400 || error.response.status == 401)
        ListViewMixin.methods.isError(error.response.status)
      ) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setWallets(state, data) {
    state.wallets = data.data;
    state.pageable = data.pageable;
  },
  setHistoryWallet(state, data) {
    state.historyWallet = data.data;
    state.pageableHistory = data.pageable;
  },
  setWalletCompany(state, data) {
    state.walletCompany = data.data;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
