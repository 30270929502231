import axios, { formToJSON } from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  users: null,
  historyUser: null,
  pageable: {},
  pageableHist: {},
  search: {
    pageSize: 20,
    // page: 0
  },
};

const getters = {
  users: (state) => state.users,
  history: (state) => state.historyUser,
  pageable: (state) => state.pageable,
  pageableHist: (state) => state.pageableHist,
  searchParams: (state) => state.search,
};

const actions = {
  /**
   * get list of operaters
   * @param {commit}
   */
  async getUsers({ commit, getters }, params) {
    console.log("user/getUsers", params);

    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params.companyIds && params.companyIds.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/accounts?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setUsers", response.data);
      return response;
    }
    return [];
  },

  async getHistory({ commit, getters }, params) {
    // console.log('history/getHistory', params);

    let searchParam = getters.searchParams;

    let pageSize =
      params["pageSize"] != undefined ? params.pageSize : searchParam.pageSize;
    let page =
      params["page"] != undefined && !isNaN(params["page"]) ? params.page : 0;

    // console.log("searchParam",searchParam);
    // console.log("pageSize", pageSize);
    // console.log("page", page);

    const response = await axios.get(
      `/status-histories/${params.id}?type=${params.type}&pageNumber=${page}&pageSize=${pageSize}`
    );
    if (response.status == 200) {
      commit("setHistory", response.data);
      return response.data.data;
    }
    return [];
  },

  async createUser({ commit }, params) {
    try {
      console.log("create user bending");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("username", params.username);
      formData.append("email", params.email);
      formData.append("password", params.password);
      formData.append("companyId", params.company.id);
      formData.append("phone", params.phone);
      formData.append("isAdmin", params.isAdmin);
      if (params.roles) {
        Array.from(params.roles).forEach((item) =>
          formData.append("roleIds", item.id)
        );
      }
      if (params.files) {
        Array.from(params.files).forEach((item) =>
          formData.append("files", item)
        );
      }
      let response = await axios.post(`/accounts`, formData, config);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateUser({ commit }, params) {
    console.log("update user bending");
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("updateUser", params);
      let formData = new FormData();
      formData.append("username", params.username);
      formData.append("email", params.email);
      if (!params.password) {
        formData.append("password", "");
      } else {
        formData.append("password", params.password);
      }

      formData.append("companyId", params.company.id);
      formData.append("phone", params.phone);
      formData.append("isAdmin", params.isAdmin);
      if (params.roles) {
        Array.from(params.roles).forEach((item) =>
          formData.append("roleIds", item.id)
        );
      }
      if (params.files) {
        Array.from(params.files).forEach((item) =>
          formData.append("files", item)
        );
      }
      let response = await axios.put(
        `/accounts/${params.id}`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async resetPassword({ commit }, params) {
    try {
      const response = await axios.post(
        `/accounts/reset_password/${params.id}`
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async changePassword({ commit }, params) {
    try {
      let formData = new FormData();
      formData.append("newPassWord", params.newPassword);
      formData.append("oldPassWord", params.password);
      const response = await axios.post(`/accounts/change_password`, formData);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async addWhiteList({ commit }, params) {
    console.log("paramssss", params);
    if (params.whiteList) {
      params.whiteLists.push({
        id: null,
        ipAddress: params.whiteList,
      });
    }
    const dataReq = params.whiteLists.map((data) => data.ipAddress);
    const dataWallet = params.wallet.id;
    console.log("dataReq", dataReq);
    try {
      const response = await axios.put(
        `/accounts/${params.id}/add-white-list`,
        {
          whiteList: dataReq,
          walletId: dataWallet,
        }
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
  async sendAppPassword({ commit }, params) {
    try {
      const response = await axios.put(
        `/accounts/${params.id}/gen_app_password`
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },
};

const mutations = {
  setUsers(state, data) {
    state.users = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageable = data.pageable;
  },
  setHistory(state, data) {
    state.historyUser = data.data.map((item, index) => ({
      ...item,
      index: index + 1,
    }));
    state.pageableHist = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
    // state.search.page = data.page
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
